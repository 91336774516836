'use client';

import IconButton from '@mui/material/IconButton';
import Print from '@mui/icons-material/Print';
import Share from '@mui/icons-material/Share';
import Stack from '@mui/material/Stack';

export default function Actions({
  print = false,
  share = false,
}: {
  print?: boolean;
  share?: boolean;
}) {
  return (
    <Stack direction='row' spacing={1}>
      {!!print && (
        <IconButton aria-label='print' size='small' onClick={() => window.print()}>
          <Print />
        </IconButton>
      )}
      {!!share && (
        <IconButton
          aria-label='share'
          size='small'
          onClick={() => {
            navigator.clipboard.writeText(window.location.href);
          }}
        >
          <Share />
        </IconButton>
      )}
    </Stack>
  );
}
